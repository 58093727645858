.cryptopage2{
    display: flex;
    flex-direction: row;
    background-color: #07061e;
    color: white;
}

.cp2-navbar{
    padding: 30px 25px 10px 25px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #5F6A85;
}

.cp2-navbar .text{
    font-size: 25px;
    font-weight: 600;
}

.cp2-navbar .sub-text{
    font-size: 16px;
    padding: 5px 0;
    font-weight: 400;
    color: #D8E4FD;
}

.cp2-content{
    padding: 30px 25px 10px 25px;
    display: flex;
    flex-direction: column;
}

.cp2-content .text{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 7px;
}

.cp2-content .sub-text{
    font-size: 16px;
    padding: 0px 0 30px;
    font-weight: 400;
    color: #D8E4FD;
}

.cp2-box{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 18px;
    flex-wrap: wrap;
}

.selection{
    display: flex;
    flex-direction: column;
}

.cryptopage2 .selection select{
    width: 25.5rem;
    height: 2.7rem;
    padding: 0 5px;
    border-radius: 4px;
    border: 1px solid #384160;
    background: #1F2138;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
    color: white;

}

.cryptopage2 .selection input{
    width: 25rem;
    height: 2.3rem;
    border-radius: 4px;
    border: 1px solid #384160;
    background: #1F2138;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
    color: white;
    padding: 0 5px;
}

.selection label{
    font-size: 12px;
    padding-bottom: 4px;
}

.cp2-btn{
    padding: 20px 0;
}



.cp2-btn{
    padding: 20px 0;
    display: flex;
    flex-direction: column;
}

.cp2-btn button{
    width: 25.8rem;
    height: 2.5rem;
    border-radius: 20px;
    background: #2070F4;
    border: none;
    font-size: 14px;
    color: white;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: capitalize;
    border: 1px solid #2070F4;
}

.cp2-box .box1{
    /* box-sizing: border-box; */
    width: 25rem;
    height: 5rem;
    border-radius: 8px;
    border: 1px solid rgba(216, 230, 253, 0.04);
    background-color: #121735;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.40);
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.cp2-box .box2{
    /* box-sizing: border-box; */
    width: 25rem;
    height: 5rem;
    border-radius: 8px;
    border: 1px solid rgba(216, 230, 253, 0.04);
    background-color: #121735;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.40);
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.cp2-box .text{
    font-size: 17px;
}

.cp2-box .sub-text{
    font-size: 14px;
    color: #D8E4FD;
}

.blockchain-binance {
    width: 25rem;
    height: 2.3rem;
    border-radius: 4px;
    border: 1px solid #384160;
    background: #1F2138;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20);
    color: white;
    padding: 0 5px;
    gap: 0.3vw;
}