.authenticationPageReal{
    color: white;
}

.apr-navbar{

    display: flex;
    justify-content: center;
    align-items: center;
}

.apr-icon{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.authenticationPageReal .text, .authenticationPageReal .sub-text{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text2{
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.6px;
    margin: 20px 0 10px 0;
}

.sub-text{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.apr-input .small-text2{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.apr-input .text2{
    width: 420px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: -1px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 3rem;
}

.input-area2{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

select{
    height: 50px;
    width: 60px;
    border: 1px solid black;
    border-radius: 7px 0 0 7px;
}

.apr-input input{
    box-sizing: border-box;
    height: 46px;
    width: 420px;
    color: #7B8DB7;
    padding: 1rem;
    font-size: 16px;
    border: 1px solid black;
    border-radius: 7px;
    border: 1px solid #384160;
    background: #1F2138;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.40);
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 5px;
}

.input-area2 input::placeholder{
    color: #7B8DB7;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0px;
}

.apr-btn{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.apr-btn button{
    width: 420px;
    height: 35px;
    border: none;
    border-radius: 17px;
    border-radius: 32px;
    background: #2070F4;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.70);
    color: white;
    font-weight: 500;
    font-size: 19px;

}

.resend{
    display: flex;
    justify-content: flex-start;
    width: 430px;
}

.small-btn{
    display: flex;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 30px;
}

.resend button{
    border: none;
    background-color: transparent;
    color: #5293FF;
}


.apr-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 2%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}  


.copyright{
    display: flex;
    justify-content: center;
}