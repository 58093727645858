.mintNumberBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
}

.mintNumberBoxRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.mintNumberBoxLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.mintNumberBoxLeft img {
  width: 32px;
  height: 32px;
}

.mintNumberBoxLeftNumber {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  gap: 8px;
  color: #ffffff;
  text-transform: capitalize;
}

.mintNumberBoxLeftNumberTier {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #8f91ef;
  padding: 4px 8px;
  background: rgba(99, 102, 233, 0.16);
  border: 0.5px solid rgba(175, 176, 244, 0.12);
  border-radius: 44px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mintNumberBoxRightStatus h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: right;
  letter-spacing: -0.02em;
  margin: 0;
  color: #ebd78e;
}

.mintNumberBoxRight p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  margin: 0;
  text-align: right;
  letter-spacing: -0.02em;

  color: #4689f6;
}

.mintNumberBoxRightProgress {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 22px;
  gap: 8px;

  background: rgba(72, 157, 93, 0.2);
  border-radius: 80px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-transform: capitalize;

  color: #489d5d;
}

.mintNumberBoxRightProgress img {
  width: 24px;
  height: 24px;

}

.diamondTier {
  background: rgba(99, 102, 233, 0.16);
  border: 0.5px solid rgba(175, 176, 244, 0.12);
  color: #8F91EF;
}

.silverTier {
  border: 0.5px solid rgba(175, 210, 244, 0.12);
  background: rgba(63, 107, 147, 0.16);  
  color: #A3D2FE;
}

.bronzeTier {
  background: #9B71E6;
  border: 0.5px solid rgba(175, 176, 244, 0.12);
  color: #FFFFFF;
}

.goldenTier {
  background: rgba(178, 164, 43, 0.16);
  border: 0.5px solid rgba(233, 220, 99, 0.12);
  color: #EFE68F;
}

.loadState {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
