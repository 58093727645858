.maincontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 50px;
}

.headerText {
  color: #fff;
  font-family: "Avenir Next";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 133.333% */
  letter-spacing: -0.6px;
}

.address {
  color: #d8e4fd;
  font-family: "Avenir Next";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.qrbox {
  width: 166px;
  height: 168px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr {
  width: 143px;
  height: 142px;
  flex-shrink: 0;
}

.privatekey {
  display: flex;
  width: max-content;
  height: 20px;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #2070f4;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
  color: #fff;
  text-align: center;
  font-family: "Avenir Next";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  text-transform: capitalize;
  margin-top: 50px;
  cursor: pointer;
  gap: 10px;
}

.link {
  display: flex;
  width: 455px;
  height: 20px;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px;
  background: #2070f4;
  border: 2px solid #2070f4;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-family: "Avenir Next";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  text-transform: capitalize;
  margin-top: 50px;
  cursor: pointer;
}
