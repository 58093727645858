.confirmationPageVirtual1 {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cpr1-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cpv1-nft {
  /* border: 1px solid white; */
  margin-top: -7rem;
  width: 12rem;
  height: 12rem;
  border-radius: 6px;
  background: #A36EFD;
  box-shadow: 0px 2.999999761581421px 30px 0px rgba(36, 196, 206, 0.48);
}

.confirmationPageVirtual1 .text,
.confirmationPageVirtual1 .sub-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.6px;
  margin: 10px 0 10px 0;
}

.sub-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #d8e4fd;
}

.cpv2-btn button {
  width: 24rem;
  margin-top: 2.5rem;
  height: 40px;
  border-radius: 20px;
  background: #2070f4;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.7);
  min-width: 20rem;
  border: none;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}
.cpv2-btn2 button {
  width: 24rem;
  margin-top: 2.5rem;
  height: 40px;
  border-radius: 20px;
  background: #95f420;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.7);
  min-width: 20rem;
  border: none;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.nft-logo {
  padding: 10px 5px;
}

.nft-number {
  position: relative;
  margin-top: 90px;
  padding: 10px 5px;
}
