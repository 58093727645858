.myNumbersWrapper {
  min-height: 100vh;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 24px;
  width: 740px;
  /* width: 80%;
  margin: 0 auto;
  background: #06061e;
  border: 1px solid rgba(216, 230, 253, 0.15);
  border-radius: 12px;
  min-height: 100vh;
  margin-top: 160px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center; */
}

.myNumbersTop {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
}

.myNumbersTop h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */
  
  letter-spacing: -0.01em;
  margin: 0;
  color: #FFFFFF;
}

.myNumbersTop p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */
  
  margin: 0;
  color: #D8E4FD;
}

.myNumbersTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgba(216, 230, 253, 0.08);
  border: 1px solid rgba(216, 230, 253, 0.08);
  border-radius: 8px;
}

.myNumbersBtmBorder {
  border: 1px solid #293047;
  margin-top: 8px;
}