.sidebar {
  width: 50rem;
  max-width: max-content;
  min-height: 100vh;
  height: auto;
  border-right: 3px solid #5f6a85;
  background-color: #121735;
  justify-content: space-between;
}
.log_out {
  /* margin-left: 2%; */
  /* margin-right: 2%; */
  width: 83%;
  /* height: 48px; */
  /* color: black; */
  display: flex;
  flex-direction: row;
  top: 820px;
  left: 8px;
  padding: 14px 18px 14px 16px;
  border-radius: 4px;
  background-color: #222945;
  justify-content: space-between;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 5%;
  cursor: pointer;
}
#log {
  color: white;

  /* align-self: flex-end; */
}
.sidebar {
  display: flex;
  flex-direction: column;
}

.links > div {
  padding: 10px 25px;
  color: #5f6a85;
}
.coming_soon {
  background-color: #f4bf4f1f;
  top: 177px;
  left: 141px;
  padding: 4px 6px 3px 6px;
  border-radius: 40px;
  gap: 8px;
  /* font-family: Avenir Next; */
  /* font-size: 10px; */
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #f4bf4fcc;
  width: auto;
  min-width: max-content;
}
.sidebar .logo {
  padding: 30px 25px;
}

.sidebar a {
  text-decoration: none;
  color: inherit;
}

.link-name {
  vertical-align: 3px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 18px;
}
.disabled {
  display: flex;
  flex-direction: row;
  min-width: max-content;
}
.disabled:hover {
  cursor: not-allowed;
}
