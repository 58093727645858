.selectBlockchainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectBlockchainTopClose {
  width: 100%;
  display: flex;
  justify-content: right;
}

.selectBlockchainTopClose img {
  max-width: 24px;
  max-height: 24px;
  cursor: pointer;
}

.selectBlockchainWrapper h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: -0.02em;
  margin: 0;
  margin-top: 12px;
  color: #ffffff;
}

.selectBlockchainWrapper p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;

  /* Secondary-text */
  margin: 0;
  margin-top: 4px;
  color: #d8e4fd;
  padding-right: 8px;
  padding-left: 8px;
}

.selectBlockchainWrapper img {
  width: 40px;
  height: 40px;
}

.selectBlockchainWrapper button {
    width: 100%;
}

.selectBlockchainOption {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 24px;
}
