.landing-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.landing-page {
  background: linear-gradient(221deg, #06061e 24.14%, #2070f4 127.74%);
  height: 100%;
}

.ud-logo {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-logo {
  height: 40px;
}
.formSection {
  width: 40%;
  margin-top: 60px;
}

.imageSection {
  margin-top: 50px;
  width: 60%;
}
.icon {
  display: flex;
  justify-content: center;
}

.heroText {
  text-align: center;
  font-family: "Avenir Next";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px; /* 120.833% */
  letter-spacing: -1.92px;

  background: linear-gradient(180deg, #fff -37.82%, #9ec1fa 119.23%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-heading {
  color: white;
  margin-top: 35px;
  text-align: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: -3.12px;
  width: auto;
}

.landing-subheading {
  color: #a7c3fb;
  text-align: center;
  margin-top: 20px;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.56px;
}

.landing-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 25px;
}

.landing-btn button {
  border-radius: 25px;
  height: 50px;
  width: 130px;
  font-size: 20px;
  color: white;
  z-index: 100;
}

.signup-btn {
  border: 2px solid #2070f4;
  background: #2070f4;
}

.login-btn {
  border-radius: 80px;
  border: 2px solid #2070f4;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

.landing-image {
}

.bottompart {
  /* margin-top: 10px; */
  /* height: vh; */
  margin-bottom: -30%;
  height: 200px;
}
.ellipse,
.iphone {
  width: 100%;
}
.img-ellipse {
  width: 98.6vw;
  /* margin-top: -50px; */
}
.ellipse {
  z-index: -10;

  /* margin-top: 10rem; */
  height: 500px;
  /* width: 90%; */
}

.iphone,
.mobile {
  margin-top: -35rem;
}
img {
  margin-top: 1.2rem;
}
.iphone {
  position: relative;
  border-radius: 40%;
  border-color: aliceblue;
  z-index: -1;
  top: 10%;

  /* left: 50%; */
}

.mobile {
  background-position: center;
  background-repeat: no-repeat;
  /* z-index: -1; */
  position: relative;
}
