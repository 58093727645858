.loginWrapper {
  display: flex;
  flex-direction: column;
  width: 392px;
  margin-top: 0rem;
  background-color: white;
  z-index: 12331232;
  height: min-content;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 12px;
}

.powered {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: -40px;
}

.companyrights {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.loginWrapperLogo {
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
}

.loginWrapper h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */

  letter-spacing: -0.02em;
  margin: 0;

  color: #ffffff;
  margin-bottom: 16px;
}

.emailInputBottomLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #3d4043;
}

.loginWrapper p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Secondary-text */
  margin: 0;
  color: #d8e4fd;
  margin-bottom: 24px;
}

.loginHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #d8e4fd;
  margin-top: 8%;
  margin-bottom: 10%;
}
.loginWrapper button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 392px;
  height: 48px;
  gap: 12px;
  border: 2px solid #2070f4;
  border-radius: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
  margin-bottom: 25px;
}
.ud-logo {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginWrapper button img {
  width: 24px;
  height: 24px;
}
img {
  padding-bottom: 5%;
}
.loginWrapperBlueBtn {
  background: #2070f4;
}

.loginWrapperTranspBtn {
  background: transparent;
  color: #3d4043;
}

.loginSwitch {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Secondary-text */

  color: #d8e4fd;
}

.loginSwitch span {
  color: #508ff6;
}
