.homePageReal {
  color: white;
}

.hpr-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hpr-icon {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.homePageReal .text,
.homePageReal .sub-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text1 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.6px;
  margin: 20px 0 10px 0;
}

.sub-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.hpr-input1 .text1 {
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 3rem;
  margin-left: -3vw;
}

.txtxt1 {
  width: 445px;
  align-items: flex-start;
}

.input-area1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.input-area1 select {
  height: 100px;
  width: 80px;
  border: 1px solid black;
  border-radius: 7px 0 0 7px;
  font-weight: 700;
  font-size: 18px;
}

.hpr-input1 input {
  /* overflow-x: scroll; */
  /* overflow-y: hidden; */
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 700;
  height: 50px;
  min-width: 400px;
  width: max-content;
  color: #7b8db7;
  padding: 1rem;
  border-radius: 0 7px 7px 0;
  background: #1f2138;
  border: 1px solid #384160;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
  letter-spacing: 0.5px;
}

.hpr-input1 input::placeholder {
  color: #7b8db7;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-weight: 500;
  width: max-content;
  /* margin: 1rem; */
  /* overflow-x: scroll; */
  /* min-width: 340px;
    width: max-content; */
  /* padding-left: 5px;    */
}

.select1 #code1 {
  border: 1px solid #384160;
  background: #2b324a;
  color: #7b8db7;
  padding-left: 0.2rem;
  border: 1px solid #384160;
}

#code1 option {
  color: #7b8db7;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.hpr-btn {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.hpr-btn button {
  width: 470px;
  height: 35px;
  border: none;
  border-radius: 17px;
  border-radius: 32px;
  background: #2070f4;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.7);
  color: white;
  font-weight: 500;
  font-size: 19px;
}

.hpr-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.copyright {
  display: flex;
  justify-content: center;
}
